import React from 'react';
import { 
  Container, 
  Navbar, 
  Card, 
  Button, 
  Row, 
  Col
} from 'react-bootstrap';
import './HomePage.css';

const AppCard = ({ title, description, imageUrl, appUrl }) => (
  <Card className="mb-4 app-card h-100">
    <Card.Img 
      variant="top" 
      src={imageUrl || "/api/placeholder/400/300"} 
      alt={title}
      className="app-image" 
    />
    <Card.Body>
      <Card.Title as="h3" className="fw-bold mb-3">{title}</Card.Title>
      <Card.Text className="text-muted mb-4">{description}</Card.Text>
    </Card.Body>
    <Card.Footer className="bg-transparent border-0 pb-4">
      <Button 
        href={appUrl}
        target="_blank"
        variant="success"
        size="lg"
        className="w-100"
      >
        Accéder à l'application
      </Button>
    </Card.Footer>
  </Card>
);

const HomePage = () => {
  const apps = [
    {
      title: "Génération d'études de marché",
      description: "Générez automatiquement des études de marché professionnelles grâce à l'intelligence artificielle. Gagnez un temps précieux dans votre analyse de marché.",
      imageUrl: process.env.PUBLIC_URL + '/market_bg.png',
      appUrl: "https://market.la-flem.me/"
    },
    {
      title: "Étude de dossier d'investissement",
      description: "Analysez en profondeur vos opportunités d'investissement avec notre outil spécialisé. Prenez des décisions éclairées basées sur des données concrètes.",
      imageUrl: process.env.PUBLIC_URL + '/invest_bg.png',
      appUrl: "https://invest.la-flem.me/"
    }
  ];

  return (
    <>
      {/* Navigation */}
      <Navbar bg="white" expand="lg" className="shadow-sm mb-4">
        <Container className="d-flex align-items-center justify-content-between">
          <Navbar.Brand href="/">
            <img 
              src={process.env.PUBLIC_URL + '/logo.png'} 
              height="40" 
              className="d-inline-block align-top"
              alt="La Flemme Logo"
            />
          </Navbar.Brand>
          <h2 className="slogan mb-0">Get ready, Get Lazy</h2>
        </Container>
      </Navbar>

      {/* Main Content */}
      <Container className="py-5">
        {/* Description */}
        <Row className="justify-content-center text-center mb-5">
          <Col lg={8}>
            <p className="lead mb-4">
              Experts en Growth Hacking et Marketing Automation, nous développons 
              des outils innovants pour optimiser vos processus business et marketing.
              Découvrez nos solutions qui vous feront gagner un temps précieux.
            </p>
          </Col>
        </Row>

        {/* Applications Grid */}
        <Row className="justify-content-center">
          {apps.map((app, index) => (
            <Col key={index} md={6} lg={6} className="mb-4">
              <AppCard {...app} />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default HomePage;
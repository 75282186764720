// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-card {
    transition: transform 0.2s ease-in-out;
    border: none;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  
  .app-card:hover {
    transform: translateY(-5px);
  }
  
  .app-image {
    height: 200px;
    object-fit: cover;
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
  }
  
  /* Style des boutons Bootstrap */
  .btn-success {
    background-color: #44FF62 !important; /* Nouveau vert du logo */
    border-color: #44FF62 !important;
    padding: 0.75rem 1.5rem;
  }
  
  .btn-success:hover {
    background-color: #3deb83 !important; /* Version légèrement plus foncée pour le hover */
    border-color: #3deb83 !important;
  }
  
  /* Typographie */
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }
  
  /* Styles du header */
  .navbar {
    padding: 1rem 0;
  }
  
  .navbar-brand img {
    transition: transform 0.2s ease-in-out;
  }
  
  .navbar-brand:hover img {
    transform: scale(1.05);
  }
  
  /* Style du slogan */
  .slogan {
    color: #44FF62; /* Nouveau vert du logo */
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 2rem;
  }
  
  @media (max-width: 768px) {
    .slogan {
      font-size: 1.2rem;
      margin-left: 1rem;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/HomePage.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,YAAY;IACZ,6CAA6C;EAC/C;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,4CAA4C;IAC5C,6CAA6C;EAC/C;;EAEA,gCAAgC;EAChC;IACE,oCAAoC,EAAE,yBAAyB;IAC/D,gCAAgC;IAChC,uBAAuB;EACzB;;EAEA;IACE,oCAAoC,EAAE,iDAAiD;IACvF,gCAAgC;EAClC;;EAEA,gBAAgB;EAChB;IACE,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA,qBAAqB;EACrB;IACE,eAAe;EACjB;;EAEA;IACE,sCAAsC;EACxC;;EAEA;IACE,sBAAsB;EACxB;;EAEA,oBAAoB;EACpB;IACE,cAAc,EAAE,yBAAyB;IACzC,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE;MACE,iBAAiB;MACjB,iBAAiB;IACnB;EACF","sourcesContent":[".app-card {\n    transition: transform 0.2s ease-in-out;\n    border: none;\n    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);\n  }\n  \n  .app-card:hover {\n    transform: translateY(-5px);\n  }\n  \n  .app-image {\n    height: 200px;\n    object-fit: cover;\n    border-top-left-radius: calc(0.375rem - 1px);\n    border-top-right-radius: calc(0.375rem - 1px);\n  }\n  \n  /* Style des boutons Bootstrap */\n  .btn-success {\n    background-color: #44FF62 !important; /* Nouveau vert du logo */\n    border-color: #44FF62 !important;\n    padding: 0.75rem 1.5rem;\n  }\n  \n  .btn-success:hover {\n    background-color: #3deb83 !important; /* Version légèrement plus foncée pour le hover */\n    border-color: #3deb83 !important;\n  }\n  \n  /* Typographie */\n  .lead {\n    font-size: 1.25rem;\n    font-weight: 300;\n  }\n  \n  /* Styles du header */\n  .navbar {\n    padding: 1rem 0;\n  }\n  \n  .navbar-brand img {\n    transition: transform 0.2s ease-in-out;\n  }\n  \n  .navbar-brand:hover img {\n    transform: scale(1.05);\n  }\n  \n  /* Style du slogan */\n  .slogan {\n    color: #44FF62; /* Nouveau vert du logo */\n    font-size: 1.5rem;\n    font-weight: bold;\n    margin-left: 2rem;\n  }\n  \n  @media (max-width: 768px) {\n    .slogan {\n      font-size: 1.2rem;\n      margin-left: 1rem;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

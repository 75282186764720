import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Navbar, Card, Button, Row, Col } from 'react-bootstrap';
import './HomePage.css';

const AppCard = ({ title, description, imageUrl, appUrl }) => (
  <Card className="mb-4 app-card h-100">
    <Card.Img 
      variant="top" 
      src={process.env.PUBLIC_URL + '/images/' + imageUrl} 
      alt={title}
      className="app-image" 
    />
    <Card.Body>
      <Card.Title as="h3" className="fw-bold mb-3">{title}</Card.Title>
      <Card.Text className="text-muted mb-4">{description}</Card.Text>
    </Card.Body>
    <Card.Footer className="bg-transparent border-0 pb-4">
      <Button 
        href={appUrl}
        target="_blank"
        rel="noopener noreferrer"
        variant="success"
        size="lg"
        className="w-100"
      >
        Accéder à l'application
      </Button>
    </Card.Footer>
  </Card>
);

const HomePage = () => {
  const pageTitle = "La Flemme - Outils de Growth Hacking & Marketing Automation";
  const pageDescription = "Optimisez votre marketing avec nos outils d'automatisation : études de marché, analyses d'investissement et engagement LinkedIn. Gagnez du temps, soyez plus efficace.";

  const apps = [
    {
      title: "Génération d'études de marché",
      description: "Générez automatiquement des études de marché professionnelles grâce à l'intelligence artificielle. Gagnez un temps précieux dans votre analyse de marché.",
      imageUrl: "market_bg.png",
      appUrl: "https://market.la-flem.me/"
    },
    {
      title: "Étude de dossier d'investissement",
      description: "Analysez en profondeur vos opportunités d'investissement avec notre outil spécialisé. Prenez des décisions éclairées basées sur des données concrètes.",
      imageUrl: "invest_bg.png",
      appUrl: "https://invest.la-flem.me/"
    },
    {
      title: "Générateur de commentaires LinkedIn",
      description: "Créez des commentaires engageants pour LinkedIn en quelques secondes. Boostez votre visibilité et votre engagement sur le réseau professionnel numéro 1.",
      imageUrl: "linkedin_bg.png",
      appUrl: "https://comment.la-flem.me/"
    }
  ];

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* Autres meta tags importants */}
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://app.la-flem.me/" />
      </Helmet>

      {/* Navigation */}
      <Navbar bg="white" expand="lg" className="shadow-sm mb-4">
        <Container className="d-flex align-items-center justify-content-between">
          <Navbar.Brand href="/">
            <img 
              src={process.env.PUBLIC_URL + '/images/logo.png'} 
              height="40" 
              className="d-inline-block align-top"
              alt="La Flemme Logo"
            />
          </Navbar.Brand>
          <h2 className="slogan mb-0">Get ready, Get Lazy</h2>
        </Container>
      </Navbar>

      {/* Main Content */}
      <Container className="py-5">
        {/* Description */}
        <Row className="justify-content-center text-center mb-5">
          <Col lg={8}>
            <p className="lead mb-4">
              Experts en Growth Hacking et Marketing Automation, nous développons 
              des outils innovants pour optimiser vos processus business et marketing.
              Découvrez nos solutions qui vous feront gagner un temps précieux.
            </p>
          </Col>
        </Row>

        {/* Applications Grid */}
        <Row className="justify-content-center">
          {apps.map((app, index) => (
            <Col key={index} md={6} lg={4} className="mb-4">
              <AppCard {...app} />
            </Col>
          ))}
        </Row>
      </Container>

      {/* Footer */}
      <footer className="text-center py-4 text-muted">
        <Container>
          <p className="mb-0">© {new Date().getFullYear()} La Flemme. Tous droits réservés.</p>
        </Container>
      </footer>
    </>
  );
};

export default HomePage;